<template>
  <Create :content="content" v-if="content" />
  <div class="text-center" v-else>
    <no-found
      :link="'/my-academy/content/ContactView'"
      :buttonText="$t('back')"
    ></no-found>
  </div>
</template>

<script>
import Create from "./Create.vue";
import ActionMixin from "../action.mixin.js";
import NoFound from "../../../Shared/NoFound.vue";
export default {
  mixins: [ActionMixin],
  components: { Create, NoFound },
  data() {
    return {
      content: null,
    };
  },
  computed: {
    contactView() {
      return this.getSettings.contactView;
    },
  },
  methods: {
    getContent() {
      const id = this.$route.params.id;

      this.content =
        this.contactView.find((item) => item.id == Number(id)) || null;
    },
  },
  mounted() {
    this.getContent();
  },
};
</script>

<style></style>
