<template>
  <ContactViewUpdate></ContactViewUpdate>
</template>

<script>
import ContactViewUpdate from "@/components/MyAcademy/Content/ContactView/Update.vue";
export default {
  components: {
    ContactViewUpdate,
  },
};
</script>

<style></style>
